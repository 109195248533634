import * as yup from "yup";

export const options = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

export const defaultValue = {
  0: {
    label: "0",
    value: 0,
  },
  1: {
    label: "1",
    value: 1,
  },
  2: {
    label: "2",
    value: 2,
  },
  3: {
    label: "3",
    value: 3,
  },
  4: {
    label: "4",
    value: 4,
  },
  5: {
    label: "5",
    value: 5,
  },
};

export const getDefaultAnswerValue = (data, id) => {
  if (id === 12) {
    return data?.answer?.value === null
      ? { height: "", weight: "" }
      : data?.answer?.valueList[0];
  }
  return data?.answer?.value
    ? defaultValue[data.answer.value]
    : defaultValue[0];
};

export const getSkinHealthScore = (data, idx, setDetails) => {
  const details = {
    3: {
      modalTitle: "1.How severe is your skin condition as of today?",
      name: "skinCondition",
      placeholder: "How severe is your skin condition as of today?",
      options: options,
      validator: {
        skinCondition: yup.string().required("skinCondition is mandatory!"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    4: {
      modalTitle: "2.How self-conscious do you feel because of your condition?",
      name: "condition",
      placeholder: "How self-conscious do you feel because of your condition?",
      options: options,
      validator: {
        condition: yup.string().required("condition is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    5: {
      modalTitle: "3.How itchy has your skin felt over the past 7 days?",
      name: "itchyCondition",
      placeholder: "How itchy has your skin felt over the past 7 days?",
      options: options,
      validator: {
        itchyCondition: yup.string().required("itchyCondition is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    6: {
      modalTitle:
        "4.How thick have the patches on your skin been over the past 7 days?",
      name: "patches",
      placeholder:
        "How thick have the patches on your skin been over the past 7 days?",
      options: options,
      validator: {
        patches: yup.string().required("patches is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    7: {
      modalTitle:
        "5.How red have the patches on your skin been over the past 7 days?",
      name: "redness",
      placeholder:
        "How red have the patches on your skin been over the past 7 days?",
      options: options,
      validator: {
        redness: yup.string().required("redness is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    8: {
      modalTitle: "6.Work / Education",
      name: "workEducation",
      placeholder: "Work / Education",
      options: options,
      validator: {
        workEducation: yup.string().required("workEducation is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    9: {
      modalTitle: "7.Social Life",
      name: "socialLife",
      placeholder: "Social Life",
      options: options,
      validator: {
        socialLife: yup.string().required("socialLife is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    10: {
      modalTitle: "8.Personal relationships",
      name: "personalRelationships",
      placeholder: "Personal relationships",
      options: options,
      validator: {
        personalRelationships: yup
          .string()
          .required("personal relationships is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    11: {
      modalTitle: "9.Sleep",
      name: "sleep",
      placeholder: "Sleep",
      options: options,
      validator: {
        sleep: yup.string().required("sleep is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
  };

  setDetails({ ...details[idx], data });
};
export const PSORIASIS_DISEASE_ID = "8295d172-f2ee-4596-a35f-9186486b4f16";
export const IBS_DISEASE_ID = "f8c5e4c3-cd3b-4fb6-9209-b4c7067bd400";
export const NERIVIO_DISEASE_ID = "25604d34-e602-4f54-8b91-b4bf71604eec";

export const getPlanNameByDiseaseId = (planName, diseaseId) => {
  const planNameWithDisease = {
    [PSORIASIS_DISEASE_ID]: {
      name: `Psoriasis - ${planName}`,
    },
    [IBS_DISEASE_ID]: {
      name: `IBS - ${planName}`,
    },
    [NERIVIO_DISEASE_ID]: {
      name: `Nerivio - ${planName}`,
    },
  };
  return planNameWithDisease[diseaseId]?.name || "";
};

export const convertCmIntoFeet = (value) => {
  const inches = value * 0.393701;
  const feet = inches / 12;
  return feet.toFixed(2);
};

export const convertFeetIntoCm = (value) => {
  const cm = value * 30.48;
  return cm.toFixed(2);
};

export const leadExclusionConditionStatus = {
  excluded: "Excluded",
  passed: "Passed",
  verification_pending: "Verification Pending",
};

export const leadPrescriptionStatus = {
  verified: "Verified",
  rejected: "Rejected",
  verification_pending: "Verification Pending",
};

export const leadDeliveryStatus = {
  order_received: "Order Received",
  order_confirmed: "Order Confirmed",
  dispatched: "Dispatched",
  delivered: "Delivered",
  returned: "Returned",
};
export const SIDEBAR_ROLES = {
  nutritionist: "nutritionist",
  psychologist: "psychologist",
  "uv coach": "uv coach",
  crm: "crm",
  admin: "admin",
  "psoriasis admin": "back office",
  doctor: "doctor",
  kam: "kam",
  chemist: "chemist",
};

export const leadExclusionConditonStatus = {
  excluded: "Excluded",
  passed: "Passed",
  verification_pending: "Verification Pending",
};

export const leadPrescriptionsStatus = {
  verified: "Verified",
  rejected: "Rejected",
  verification_pending: "Verification Pending",
};

export const formattedAddress = (address) => {
  return `${address?.address},\n ${address?.city} - ${address?.pincode}, ${address?.state}`;
};

export const activityLevels = [
  {
    label: "Sedentary",
    value: "Sedentary (little or no exercise)",
    measure: 1,
  },
  {
    label: "Lightly Active",
    value: "Lightly active (light exercise/sports 1-3 days/week)",
    measure: 2,
  },
  {
    label: "Moderately Active",
    value: "Moderately active (moderate exercise/sports 3-5 days/week)",
    measure: 3,
  },
  {
    label: "Very Active",
    value: "Very active (hard exercise/sports 6-7 days a week)",
    measure: 4,
  },
  {
    label: "Super Active",
    value: "Super active (very hard exercise & physical job or 2x training)",
    measure: 5,
  },
];

export const restrictedDays = [
  {
    label: "Sunday",
    value: "Sunday",
    measure: "0",
  },
  {
    label: "Monday",
    value: "Monday",
    measure: "1",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
    measure: "2",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
    measure: "3",
  },
  {
    label: "Thursday",
    value: "Thursday",
    measure: "4",
  },
  {
    label: "Friday",
    value: "Friday",
    measure: "5",
  },
  {
    label: "Saturday",
    value: "Saturday",
    measure: "6",
  },
  {
    label: "None",
    value: "None",
    measure: "7",
  },
];

export const meatPreferences = [
  {
    label: "Egg",
    value: "Egg",
    measure: 1,
  },
  {
    label: "Chicken",
    value: "Chicken",
    measure: 2,
  },
  {
    label: "Goat",
    value: "Goat",
    measure: 3,
  },
  {
    label: "Beef",
    value: "Beef",
    measure: 4,
  },
  {
    label: "Pork",
    value: "Pork",
    measure: 5,
  },
  {
    label: "Fish",
    value: "Fish",
    measure: 6,
  },
  {
    label: "Other Sea Food",
    value: "Other Sea Food",
    measure: 7,
  },
  {
    label: "Other Poultry",
    value: "Other Poultry",
    measure: 8,
  },
];

export const eatingHabitsMap = {
  vegan: 1,
  veg: 2,
  nonVegetarian: 3,
  eggitarian: 4,
};

export const heightQuestionLabel = "What's your height?";
export const weightQuestionLabel = "What's your weight?";

export const meals = [
  {
    name: "earlyMorning",
    title: "Early Morning",
    value: "earlyMornings",
  },
  {
    name: "breakfast",
    title: "Breakfast",
    value: "breakfast",
  },
  {
    name: "middaySnack",
    title: "Mid Day Snack",
    value: "midDaySnacks",
  },
  {
    name: "lunch",
    title: "Lunch",
    value: "lunch",
  },
  {
    name: "eveningSnack",
    title: "Evening Snack",
    value: "eveningSnacks",
  },
  {
    name: "dinner",
    title: "Dinner",
    value: "dinner",
  },
  {
    name: "bedtime",
    title: "Bedtime",
    value: "bedTime",
  },
  {
    name: "outsideFood",
    title: "Outside Food",
    value: "outsideFood",
  },
];

// export const stateCuisineMapping = {
//   Andhra: 1,
//   Assamese: 2,
//   Bengali: 3,
//   Bihari: 4,
//   Chattisgarhi: 5,
//   Goan: 6,
//   Gujarati: 7,
//   Himachali: 8,
//   Jharkhandi: 9,
//   Karnataka: 10,
//   Kerala: 12,
//   Maharashtrian: 13,
//   Haryanvi: 14,
//   "Madhya Pradesh": 15,
//   Nagaland: 16,
//   Odiya: 17,
//   Punjabi: 18,
//   Rajasthani: 19,
//   "Tamil Nadu": 20,
//   Telangana: 21,
//   "Uttar Pradesh": 22,
//   Arunachali: 23,
//   Manipuri: 24,
//   Meghalayan: 25,
//   Mizo: 26,
//   Sikkimese: 27,
//   Tripuri: 28,
//   Uttarakhand: 29,
// };

export const stateCuisineMapping = {
  "Andhra Pradesh": 1,
  "Arunachal Pradesh": 2,
  Assam: 3,
  Bihar: 4,
  Chattisgarh: 5,
  Goa: 6,
  Gujarat: 7,
  Haryana: 8,
  "Himachal Pradesh": 9,
  Jharkand: 10,
  Karnataka: 11,
  Kerala: 12,
  "Madhya Pradesh": 13,
  Maharashtra: 14,
  Manipur: 15,
  Meghalaya: 16,
  Mizoram: 17,
  Nagaland: 18,
  Odisha: 19,
  Punjab: 20,
  Rajasthan: 21,
  Sikkim: 22,
  "Tamil Nadu": 23,
  Telangana: 24,
  Tripura: 25,
  Uttarakhand: 26,
  "Uttar Pradesh": 27,
  "West Bengal": 28,
};

export const regionCuisineMapping = {
  Andhra: 4,
  Assamese: 1,
  Bengali: 1,
  Bihari: 1,
  Chattisgarhi: 2,
  Goan: 5,
  Gujarati: 5,
  Himachali: 2,
  Jharkhandi: 2,
  Karnataka: 4,
  Kerala: 4,
  Maharashtrian: 5,
  Haryanvi: 5,
  "Madhya Pradesh": 2,
  Nagaland: 3,
  Odiya: 1,
  Punjabi: 5,
  Rajasthani: 5,
  "Tamil Nadu": 4,
  Telangana: 4,
  "Uttar Pradesh": 2,
  Arunachali: 3,
  Manipuri: 3,
  Meghalayan: 3,
  Mizo: 3,
  Sikkimese: 3,
  Tripuri: 3,
  Uttarakhand: 2,
};

export const localCuisineMapping = {
  Awadhi: 1,
  Chettinad: 2,
  "Coastal Karnataka": 3,
  Kodava: 4,
  Indori: 5,
  Kathiyawadi: 7,
  Konkan: 10,
  Lucknowi: 12,
  Mangalorean: 15,
  Marwari: 16,
  Sindhi: 17,
  Surati: 18,
  Udupi: 19,
  Mughlai: 20,
  Malabari: 21,
  Hyderabadi: 22,
  Amdavadi: 23,
  Kutchi: 24,
  "Uttara Karnataka": 25,
  "Dakshina Karnataka": 26,
  Saraswat: 27,
  Deshastha: 28,
  Vidarbha: 29,
  Khandeshi: 30,
  Garhwali: 31,
  Kumaoni: 32,
};

export const allergyMapping = {
  Dairy: 1,
  Eggs: 2,
  Fish: 6,
  None: 11,
  Nuts: 3,
  Sea_Food: 19,
  Sesame: 8,
  Shell_Fish: 7,
  Soy: 5,
  Tree_Nuts: 4,
  Wheat: 10,
};
export const phases = {
  GUT_CLEANSE: "Gut Cleanse",
  SUSTENANCE: "Sustenance",
  REINTRODUCTION: "Reintroduction",
  ENROLLMENT: "Enrollment",
  FULL_PROGRAM_EXTENSION: "Full Program Extension",
  NUTRITIONIST_SUPPORT_EXTENSION: "Nutritionist support extension",
  PSYCHOLOGY_SUPPORT_EXTENSION: "Psychology support Extension",
};

export const orderCreatedByIconSrc = {
  chemist: {
    src: "/images/icons/chemist-logo.svg",
    title: "Chemist",
    text: "text-success-100",
    border: "border-success-100",
  },
  "psoriasis admin": {
    src: "/images/icons/backoffice-logo.svg",
    title: "Back Office",
    text: "text-secondary-800",
    border: "border-secondary-800",
  },
};


export const durationComorbidity = [
  { label: "1 year", value: "1 year" },
  { label: "2 year", value: "2 year" },
  { label: "3 year", value: "3 year" },
  { label: "4 year", value: "4 year" },
  { label: "5 year", value: "5 year" },
];

export const whoCooksOptions = [
  { label: "Self", value: "Self" },
  { label: "Wife/Mother", value: "Wife/Mother" },
  { label: "Cook/Chef", value: "Cook/Chef" },
  { label: "Others", value: "Others" },
];

export const currentImpactOfNafldOptions = [
  { label: "No impact on life", value: "No impact on life" },
  {
    label: "Unable to eat food without worrying about calories and weight gain",
    value: "Unable to eat food without worrying about calories and weight gain",
  },
  {
    label: "Have to always rely on medications",
    value: "Have to always rely on medications",
  },
  {
    label: "Lack of self-confidence",
    value: "Lack of self-confidence",
  },
  {
    label: "Unable to concentrate on work/studies",
    value: "Unable to concentrate on work/studies",
  },
  {
    label: "Unable to go in to work due to symptoms",
    value: "Unable to go in to work due to symptoms",
  },
  { label: "Lowered work performance", value: "Lowered work performance" },
  {
    label: "Unable to go out to eat with friends/family",
    value: "Unable to go out to eat with friends/family",
  },
  {
    label:
      "Unable to attend functions/festivities due to fear of condition progression",
    value:
      "Unable to attend functions/festivities due to fear of condition progression",
  },
  {
    label:
      "Strained relationship with family/friends due to symptoms/condition",
    value:
      "Strained relationship with family/friends due to symptoms/condition",
  },
  { label: "Others", value: "Others" },
];

export const motivationGoalPrograms = [
  {
    label: "Prevent progression of fatty liver",
    value: "Prevent progression of fatty liver",
  },
  { label: "Maintain a healthy weight", value: "Maintain a healthy weight" },
  {
    label: "Manage comorbidities",
    value: "Manage comorbidities",
  },
  {
    label: "Understand what to eat to manage NAFLD",
    value: "Understand what to eat to manage NAFLD",
  },
  {
    label: " Get physically fit",
    value: "Get physically fit",
  },
  { label: "Others", value: "Others" },
];

export const habitsOptions = [
  { label: "Smoking", value: "Smoking" },
  { label: "Alcohol", value: "Alcohol" },
  { label: "None", value: "none" },
  { label: "Others", value: "Others" },
];

export const callCompletedReasons = [
  { label: "Patient did not respond", value: "Patient did not respond" },
  { label: "Patient asked to reschedule to another date ", value: "Patient asked to reschedule to another date " },
  { label: "Others", value: "Others" },
];

export const mealsNotLoggedReasons = [
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient is not tech-savvy", value: "Patient is not tech-savvy" },
  { label: "Issue with the app ", value: "Issue with the app " },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Others", value: "Others" },
];

export const nutritionChallengesReasons = [
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient did not like the challenge", value: "Patient did not like the challenge" },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Others", value: "Others"},
]

export const weightsNotLoggedReasons = [
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient is not tech-savvy", value: "Patient is not tech-savvy" },
  { label: "Issue with the app ", value: "Issue with the app " },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Others", value: "Others" },
];

export const activityNotLoggedReasons = [
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient is not tech-savvy", value: "Patient is not tech-savvy" },
  { label: "Issue with the app ", value: "Issue with the app " },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Others", value: "Others" },
];

export const adherenceOption = [
  { label: "0%", value: "0%" },
  { label: "10%", value: "10%" },
  { label: "20%", value: "20%" },
  { label: "30%", value: "30%" },
  { label: "40%", value: "40%" },
  { label: "50%", value: "50%" },
  { label: "60%", value: "60%" },
  { label: "70%", value: "70%" },
  { label: "80%", value: "80%" },
  { label: "90%", value: "90%" },
  { label: "100%", value: "100%" },
]
export const medicationStatusOption = [
  { label: "Feeling better so stopped without informing doctor", value: "Feeling better so stopped without informing doctor" },
  { label: "Feeling better so stopped post doctor approval", value: "Feeling better so stopped post doctor approval" },
  { label: "Still consuming same dosage/frequency", value: "Still consuming same dosage/frequency" },
  { label: "Reduced dosage/frequency", value: "Reduced dosage/frequency" },
  { label: "Others", value: "Others" },
]
export const medicationStatusOptionNafld = [
  { label: "Feeling better so stopped without informing doctor", value: "Feeling better so stopped without informing doctor" },
  { label: "Feeling better so stopped post doctor approval", value: "Feeling better so stopped post doctor approval" },
  { label: "Still consuming same dosage/frequency", value: "Still consuming same dosage/frequency" },
  { label: "Reduced dosage/frequency", value: "Reduced dosage/frequency" },
  { label: "Not Applicable (Patient Is not on any MH specific medication regimen)", value: "Not Applicable (Patient Is not on any MH specific medication regimen)" },
  { label: "Others", value: "Others" },
]

export const nutritionChallengesThisWeek = [
  { label: "Plate Method", value: "Plate Method" },
  { label: "Early Dinner Challenge", value: "Early Dinner Challenge" },
  { label: "Cooking Oil Challenge", value: "Cooking Oil Challenge" },
  { label: "Healthy Fats Challenge", value: "Healthy Fats Challenge" },
  { label: "Sugar Detox Challenge", value: "Sugar Detox Challenge" },
  { label: "Fermented Foods Challenge", value: "Fermented Foods Challenge" },
  { label: "Anti-inflammatory Challenge", value: "Anti-inflammatory Challenge" },
  { label: "Sodium Restriction Challenge", value: "Sodium Restriction Challenge" },
  { label: "Vitamin Challenge", value: "Vitamin Challenge" },
  { label: "Fiber and Protein Challenge", value: "Fiber and Protein Challenge" },
  { label: "No challenge given", value: "no" }
];

export const mealLoggingReasonOptions = [
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient is not tech-savvy", value: "Patient is not tech-savvy" },
  { label: "Issue with the app ", value: "Issue with the app " },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Others", value: "Others" },
];
export const flareupLoggingReasonOptions = [
  { label: "Patient was busy ", value: "Patient was busy " },
  { label: "Patient is not tech-savvy", value: "Patient is not tech-savvy" },
  { label: "Issue with the app", value: "Issue with the app" },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Others", value: "Others" },
];
export const durationOption = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "Not relevant for this symptom", value: "Not relevant for this symptom" },
];
export const intensityOption = [
  { label: "0%", value: "0%" },
  { label: "10%", value: "10%" },
  { label: "20%", value: "20%" },
  { label: "30%", value: "30%" },
  { label: "40%", value: "40%" },
  { label: "50%", value: "50%" },
  { label: "60%", value: "60%" },
  { label: "70%", value: "70%" },
  { label: "80%", value: "80%" },
  { label: "90%", value: "90%" },
  { label: "100%", value: "100%" },
  { label: "Not relevant for this symptom", value: "Not relevant for this symptom" },
]

export const goalsOfTheSessions = [
  {
    label: "To create rapport and set expectations with the patient for the program",
    value: "To create rapport and set expectations with the patient for the program",
  },
  {
    label: "To equip the patient with practical techniques to manage stress",
    value: "To equip the patient with practical techniques to manage stress",
  },
  {
    label: "To promote and strengthen stress management with the patient",
    value: "To promote and strengthen stress management with the patient",
  },
  {
    label: "To strengthen coping mechanism to battle challenges in IBS",
    value: "To strengthen coping mechanism to battle challenges in IBS",
  },
  {
    label: "To elicit feedback from the patient about the program and co-create the maintenance self-care plan for journey ahead",
    value: "To elicit feedback from the patient about the program and co-create the maintenance self-care plan for journey ahead",
  },
  {
    label: "Others",
    value: "Others",
  }
]

export const goalsOfNafldSessions = [
  {
    label: "To create rapport and set expectations with the patient for the program",
    value: "To create rapport and set expectations with the patient for the program",
  },
  {
    label: "To equip the patient with practical techniques to manage stress",
    value: "To equip the patient with practical techniques to manage stress",
  },
  {
    label: "To promote and strengthen stress management with the patient",
    value: "To promote and strengthen stress management with the patient",
  },
  {
    label: "To strengthen coping mechanism to battle challenges in NAFLD",
    value: "To strengthen coping mechanism to battle challenges in NAFLD",
  },
  {
    label: "To elicit feedback from the patient about the program and co-create the maintenance self-care plan for journey ahead",
    value: "To elicit feedback from the patient about the program and co-create the maintenance self-care plan for journey ahead",
  },
  {
    label: "Others",
    value: "Others",
  }
]

export const comorbiditiesOfNafld = [
  {
    label: "Type 2 Diabetes",
    value: "Type 2 Diabetes",
  },
  {
    label: "Hypertension",
    value: "Hypertension",
  },
  {
    label: "Hypothyroidism",
    value: "Hypothyroidism",
  },
  {
    label: "PCOS/PCOD",
    value: "PCOS/PCOD",
  },
  {
    label: "Obesity",
    value: "Obesity",
  },
  {
    label: "Renal Disease",
    value: "Renal Disease",
  },
  {
    label: "Coronary Artery Disease",
    value: "Coronary Artery Disease",
  },
  {
    label: "Others",
    value: "Others",
  }
]

export const comorbiditiesDuration = [
  {
    label: "1 year",
    value: "1 year",
  },
  {
    label: "2 years",
    value: "2 years",
  },
  {
    label: "3 years",
    value: "3 years",
  },
  {
    label: "4 years",
    value: "4 years",
  },
  {
    label: "5+ years",
    value: "5+ years",
  },
]

export const exclusionCriteriaOptions = [
  {
    label: "Delirium/Psychosis",
    value: "Delirium/Psychosis",
  },
  {
    label: "Schizophrenia",
    value: "Schizophrenia",
  },
  {
    label: "Obsessive Compulsive Disorder",
    value: "Obsessive Compulsive Disorder",
  },
  {
    label: "Hypochondriasis",
    value: "Hypochondriasis",
  },
  {
    label: "Bipolar Disorder",
    value: "Bipolar Disorder",
  },
  {
    label: "Eating Disorders",
    value: "Eating Disorders",
  },
  {
    label: "Panic Disorder",
    value: "Panic Disorder",
  },
  {
    label: "Anxiety",
    value: "Anxiety",
  },
  {
    label: "Depression",
    value: "Depression",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const exclusionCriteriaOptionsNAFLD = [
  {
    label: "Delirium/Psychosis",
    value: "Delirium/Psychosis",
  },
  {
    label: "Schizophrenia",
    value: "Schizophrenia",
  },
  {
    label: "Obsessive Compulsive Disorder",
    value: "Obsessive Compulsive Disorder",
  },
  {
    label: "Hypochondriasis",
    value: "Hypochondriasis",
  },
  {
    label: "Bipolar Disorder",
    value: "Bipolar Disorder",
  },
  {
    label: "Eating Disorders",
    value: "Eating Disorders",
  },
  {
    label: "Panic Disorder",
    value: "Panic Disorder",
  },
  {
    label: "Anxiety",
    value: "Anxiety",
  },
  {
    label: "Depression",
    value: "Depression",
  },
  {
    label: "Others",
    value: "Others",
  },
  {
    label: "None",
    value: "None",
  },
]

export const emotionalTriggersOptions = [
  {
    label: "Physical distress caused from IBS",
    value: "Physical distress caused from IBS",
  },
  {
    label: "Mental distress caused from IBS",
    value: "Mental distress caused from IBS",
  },
  {
    label: "Work Stress",
    value: "Work Stress",
  },
  {
    label: "Relationship Stress",
    value: "Relationship Stress",
  },
  {
    label: "Financial Stress",
    value: "Financial Stress",
  },
  {
    label: "Sleep Concern",
    value: "Sleep Concern",
  },
  {
    label: "Body Image Challenges",
    value: "Body Image Challenges",
  },
  {
    label: "Weight Management",
    value: "Weight Management",
  },
  {
    label: "Grief/Loss",
    value: "Grief/Loss",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const relevantProgramOptions = [
  {
    label: "Yoga Programs",
    value: "Yoga Programs",
  },
  {
    label: "Art of Living",
    value: "Art of Living",
  },
  {
    label: "Meditation Programs",
    value: "Meditation Programs",
  },
  {
    label: "Seeing a therapist outside the program",
    value: "Seeing a therapist outside the program",
  },
  {
    label: "Others",
    value: "Others",
  },

]

export const appetiteOptions = [
  {
    label: "Adequate",
    value: "Adequate",
  },
  {
    label: "Inadequate",
    value: "Inadequate",
  },
]

export const majorLifeEventOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Marriage",
    value: "Marriage",
  },
  {
    label: "Separation/Divorce",
    value: "Separation/Divorce",
  },
  {
    label: "Miscarriage",
    value: "Miscarriage",
  },
  {
    label: "Moved to a different city/country",
    value: "Moved to a different city/country",
  },
  {
    label: "Job Change",
    value: "Job Change",
  },
  {
    label: "Unemployment",
    value: "Unemployment",
  },
  {
    label: "Milestone",
    value: "Milestone",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const educationOptions = [
  {
    label: "SSC",
    value: "SSC",
  },
  {
    label: "HSC",
    value: "HSC",
  },
  {
    label: "Graduation",
    value: "Graduation",
  },
  {
    label: "Post-Graduation",
    value: "Post-Graduation",
  },
  {
    label: "Masters",
    value: "Masters",
  },
  {
    label: "Doctorate",
    value: "Doctorate",
  },
  {
    label: "Others",
    value: "Others",
  },

]

export const familyStructureOptions = [
  {
    label: "Nuclear",
    value: "Nuclear",
  },
  {
    label: "Joint",
    value: "Joint",
  },
  {
    label: "NA",
    value: "NA",
  },
  {
    label: "Others",
    value: "Others",
  },

]

export const caregiverRelations = [
  { label: "Spouse", value: "Spouse" },
  { label: "Parent", value: "Parent" },
  { label: "Sibling", value: "Sibling" },
  { label: "Others", value: "Others" },
];

export const psychologistCallCompleted = [
  { label: "Patient did not respond", value: "Patient did not respond" },
  { label: "Patient asked to reschedule to another date ", value: "Patient asked to reschedule to another date " },
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Others", value: "Others" },
];


export const clinicalObservationOptions = [
  { label: "Patient seems to be doing well emotionally", value: "Patient seems to be doing well emotionally" },
  { label: "Patient doesn't seem to be doing well emotionally", value: "Patient doesn't seem to be doing well emotionally" },
  { label: "Others", value: "Others" },
];

export const statusEmotionalTriggersOptions = [
  { label: "Better since last week", value: "Better since last week" },
  { label: "Worsened since last week", value: "Worsened since last week" },
  { label: " Same as last week", value: " Same as last week" },
]


export const patientWorksheetsDetailsOptions = [
  { label: "Patient was unaware of thought worksheets available on app", value: "Patient was unaware of thought worksheets available on app" },
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient does not find value in filling out the worksheets", value: "Patient does not find value in filling out the worksheets" },
  { label: "Language barrier", value: "Language barrier" },
  { label: "App wasn't working", value: "App wasn't working" },
  { label: "Others", value: "Others" },

]

export const patientWatchRelaxationVideosOptions = [
  { label: "Patient was unaware of relaxation videos available on app", value: "Patient was unaware of relaxation videos available on app" },
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient does not find value in watching the video", value: "Patient does not find value in watching the video" },
  { label: "Language Barrier", value: "Language Barrier" },
  { label: "App wasn't working", value: "App wasn't working" },
  { label: "Patient has seen the video in past and knows how to perform the technique", value: "Patient has seen the video in past and knows how to perform the technique" },
  { label: "Others", value: "Others" },
]

export const techniqueIntroducedOptions = [
  { label: "Progressive Muscle Relaxation", value: "Progressive Muscle Relaxation" },
  { label: "Cue-Controlled Relaxation", value: "Cue-Controlled Relaxation" },
  { label: "Countdown Relaxation", value: "Countdown Relaxation" },
  { label: "Guided Imagery", value: "Guided Imagery" },
]

export const additionalTechniqueIntroducedOptions = [
  { label: "Mindful Eating", value: "Mindful Eating" },
  { label: "Sleep Hygiene", value: "Sleep Hygiene" },
  { label: "Anger Management ", value: "Anger Management " },
  { label: "Grounding Technique", value: "Grounding Technique" },
  { label: "Self-compassion", value: "Self-compassion" },
  { label: "Gratitude Journaling ", value: "Gratitude Journaling " },
  { label: "Others", value: "Others" },
]

export const concersNafld = [
  {
    label: "Yes", value: "Yes"
  },
  {
    label: "No", value: "No"
  },
]

export const emotionalTriggersOptionsNafld = [
  {
    label: "Physical distress caused from NAFLD",
    value: "Physical distress caused from NAFLD",
  },
  {
    label: "Mental distress caused from NAFLD",
    value: "Mental distress caused from NAFLD",
  },
  {
    label: "Work Stress",
    value: "Work Stress",
  },
  {
    label: "Relationship Stress",
    value: "Relationship Stress",
  },
  {
    label: "Financial Stress",
    value: "Financial Stress",
  },
  {
    label: "Sleep Concern",
    value: "Sleep Concern",
  },
  {
    label: "Body Image Challenges",
    value: "Body Image Challenges",
  },
  {
    label: "Weight Management",
    value: "Weight Management",
  },
  {
    label: "Grief/Loss",
    value: "Grief/Loss",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const emotionalTriggersOptionsNafldFollowUp = [
  {
    label: "Physical distress caused from NAFLD",
    value: "Physical distress caused from NAFLD",
  },
  {
    label: "Mental distress caused from NAFLD",
    value: "Mental distress caused from NAFLD",
  },
  {
    label: "Work Stress",
    value: "Work Stress",
  },
  {
    label: "Relationship Stress",
    value: "Relationship Stress",
  },
  {
    label: "Financial Stress",
    value: "Financial Stress",
  },
  {
    label: "Sleep Concern",
    value: "Sleep Concern",
  },
  {
    label: "Body Image Challenges",
    value: "Body Image Challenges",
  },
  {
    label: "Weight Management",
    value: "Weight Management",
  },
  {
    label: "Grief/Loss",
    value: "Grief/Loss",
  },
  {
    label: "Others",
    value: "Others",
  },
  {
    label: "None",
    value: "None",
  },
]

export const impactOfNafldOnLife = [
  {
    label: "No impact on life",
    value: "No impact on life",
  },
  {
    label: "Unable to eat food without worrying about calories and weight gain",
    value: "Unable to eat food without worrying about calories and weight gain",
  },
  {
    label: "Have to always rely on medications",
    value: "Have to always rely on medications",
  },
  {
    label: "Lack of self-confidence",
    value: "Lack of self-confidence",
  },
  {
    label: "Unable to concentrate on work/studies",
    value: "Unable to concentrate on work/studies",
  },
  {
    label: "Unable to go in to work due to symptoms",
    value: "Unable to go in to work due to symptoms",
  },
  {
    label: "Lowered work performance",
    value: "Lowered work performance",
  },
  {
    label: "Unable to go out to eat with friends/family",
    value: "Unable to go out to eat with friends/family",
  },
  {
    label: "Unable to attend functions/festivities due to fear of condition progression",
    value: "Unable to attend functions/festivities due to fear of condition progression",
  },
  {
    label: "Strained relationship with family/friends due to symptoms/condition",
    value: "Strained relationship with family/friends due to symptoms/condition",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const motivationToJoinPrograms = [
  {
    label: "Prevent progression of fatty liver",
    value: "Prevent progression of fatty liver",
  },
  {
    label: "Maintain a healthy weight",
    value: "Maintain a healthy weight",
  },
  {
    label: "Manage comorbidities",
    value: "Manage comorbidities",
  },
  {
    label: "Understand what to eat to manage NAFLD",
    value: "Understand what to eat to manage NAFLD",
  },
  {
    label: "Get physically fit",
    value: "Get physically fit",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const patientWorksheetsDetailsOptionsNAFLD = [
  { label: "Patient was unaware of mood check-in available on app", value: "Patient was unaware of mood check-in available on app" },
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient does not find value in filling out their mood", value: "Patient does not find value in filling out their mood" },
  { label: "Language barrier", value: "Language barrier" },
  { label: "App wasn't working", value: "App wasn't working" },
  { label: "Others", value: "Others" },
]

export const behaviouralChallengeNAFDL = [
  { label: "Physical Activity Week 1", value: "Physical Activity Week 1" },
  { label: "Physical Activity Week 2", value: "Physical Activity Week 2" },
  { label: "Physical Activity Week 3", value: "Physical Activity Week 3" },
  { label: "Sleep Week 1", value: "Sleep Week 1" },
  { label: "Sleep Week 2", value: "Sleep Week 2" },
  { label: "Sleep Week 3", value: "Sleep Week 3" },
  { label: "Stress Week 1", value: "Stress Week 1" },
  { label: "Stress Week 2", value: "Stress Week 2" },
  { label: "Stress Week 3", value: "Stress Week 3" },
  { label: "No challenge given", value: "No challenge given" },
]
export const patientWatchAndFollowBehavioralChallengesReasonOptions = [
  { label: "Patient was unaware of behavioral challenges available on app", value: "Patient was unaware of behavioral challenges available on app" },
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient does not find value in doing the challenge", value: "Patient does not find value in doing the challenge" },
  { label: "Language barrier", value: "Language barrier" },
  { label: "App wasn't working", value: "App wasn't working" },
  { label: "Patient already follows the challenge as part of daily routine", value: "Patient already follows the challenge as part of daily routine" },
  { label: "Others", value: "Others" },
]

export const NafldMotivationGoalPrograms = [
  {
    label: "Prevent progression of fatty liver",
    value: "Prevent progression of fatty liver",
  },
  { label: "Maintain a healthy weight", value: "Maintain a healthy weight" },
  {
    label: "Manage comorbidities",
    value: "Manage comorbidities",
  },
  {
    label: "Understand what to eat to manage NAFLD",
    value: "Understand what to eat to manage NAFLD",
  },
  {
    label: " Get physically fit",
    value: "Get physically fit",
  },
  { label: "Others", value: "Others" },
];

export const NafldActivityNotLoggedReasons = [
  { label: "Patient was busy", value: "Patient was busy" },
  { label: "Patient is not tech-savvy", value: "Patient is not tech-savvy" },
  { label: "Issue with the app ", value: "Issue with the app " },
  { label: "Patient forgot", value: "Patient forgot" },
  { label: "Patient did not do any exercise in the past week", value: "Patient did not do any exercise in the past week" },
  { label: "Others", value: "Others" },
];
