import { getAuth, isAuthenticated } from "services/identity.service";
import { isProtected } from "./Security";
import hasPermission from "lib/permission";

const isBrowser = () => typeof window !== "undefined";

const WithAuth = ({ router, children }) => {
  const auth = getAuth();
  const patientsRoutes = [
    "/nerivio/login",
    "/nerivio/dashboard",
    "/nerivio/history",
    "/nerivio/date-time",
    "/nerivio/questionnaire",
  ];

  let url;
  switch (auth?.role) {
    case "doctor":
    case "kam":
      url = "/doctor-panel/patients";
      break;
    case "patient":
      url = "/nerivio/dashboard";
      break;
    default:
      url = "/";
      break;
  }
  if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
    if (patientsRoutes.includes(router.pathname)) {
      router.replace("/nerivio/login");
    } else {
      router.replace("/login");
    }
    // return null;
  }
  if (isBrowser() && isAuthenticated(auth) && !isProtected(router.pathname)) {
      router.replace(url);
      // return null;
  }

  if (
    isBrowser() &&
    isAuthenticated(auth) &&
    isProtected(router.pathname) &&
    !hasPermission(router.pathname, auth)
  ) {
    router.replace(url);
    // return null;
  }
  return children;
};

export default WithAuth;
