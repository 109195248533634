import * as HttpService from "./http.service";
import {
  SEND_OTP_URL,
  SEND_PATIENT_OTP_URL,
  VERIFY_OTP_URL,
  CREATE_USER_IN_FIREBASE,
  LOGIN_WITH_FIREBASE,
  LOGOUT_USER,
  VERIFY_PATIENT_OTP_URL,
} from "./url.service";

export const sendOtpService = ({ mobileNumber }) => {
  return HttpService.postWithoutAuth(SEND_OTP_URL, { mobileNumber });
};

export const verifyOtpService = ({ mobile, session, code }) => {
  return HttpService.postWithoutAuth(VERIFY_OTP_URL, { mobile, session, code });
};

export const createUserInFirebase = (id ) => {
  return HttpService.postWithAuth(CREATE_USER_IN_FIREBASE, { id })
}

export const loginWithFriebase = (mobile) => {
  return HttpService.postWithAuth(LOGIN_WITH_FIREBASE, { mobile });
};

export const logout = (userId) => {
  return HttpService.putWithAuth(LOGOUT_USER(userId));
};
export const sendPatientOtpService = ({
  name,
  mobileNumber,
  doctorId,
  diseaseId,
}) => {
  return HttpService.postWithoutAuth(SEND_PATIENT_OTP_URL, {
    name,
    mobileNumber,
    doctorId,
    diseaseId,
  });
};
export const verifyPatientOtpService = ({ mobile, session, code }) => {
  return HttpService.postWithoutAuth(VERIFY_PATIENT_OTP_URL, { mobile, session, code });
};