import EmptyLayout from "./EmptyLayout";
import SideBarLayout from "./SidebarLayout";
import DDLayout from "./DDLayout";

const LayoutConfig = [
  {
    path: "/nerivio",
    Layout: EmptyLayout,
  },
  {
    path: "/login",
    Layout: EmptyLayout,
  },
  {
    path: "/doctor-panel",
    Layout: DDLayout,
  }
];

const getLayoutConfig = (path) => {
  let config = LayoutConfig.find((configItem) =>
    path.includes(configItem.path)
  );
  if (config) return config;
  else return { Layout: SideBarLayout };
};

export { getLayoutConfig };
