import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAlertNotification, getAlertNotificationTotalCount, updateAlertNotification } from './alert-notification.service';


export const useAlertNotificationQuery = (params) => {
    return useQuery(['alert-notification', params], () => getAlertNotification(params), {
        refetchInterval: 60000,
    });
}

export function useUpdateAlertNotification(params) {
    const queryClient = useQueryClient();
    const updateAlertNotificationMutation = useMutation({
        mutationFn: ({ id }) => updateAlertNotification({ id }),
        onSuccess: (res) => {
            queryClient.invalidateQueries(['alert-notification', params]).then((res) => queryClient.invalidateQueries(['alert-notification-count']));
        }
    })

    return { updateAlertNotificationMutation }
}

export const useAlertNotificationTotalCountQuery = (params) => {
    return useQuery(['alert-notification-count', params], () => getAlertNotificationTotalCount(params), {
        refetchInterval: 60000,
    });
}