import * as HttpService from "./http.service";
import {  GET_ALERT_NOTIFICATION_URL, UPDATE_ALERT_NOTIFICATION_URL, GET_ALERT_NOTIFICATION_TOTAL_COUNT } from './url.service'

export const getAlertNotification =({ type, hcId, isRead, offset, limit }) => {
    return HttpService.getWithAuth(GET_ALERT_NOTIFICATION_URL({ type, hcId, isRead, offset, limit }));
};

export const updateAlertNotification =({ id }) => {
    return HttpService.putWithAuth(UPDATE_ALERT_NOTIFICATION_URL(id));
};

export const getAlertNotificationTotalCount = ({ hcId, role }) => {
    return HttpService.getWithAuth(GET_ALERT_NOTIFICATION_TOTAL_COUNT({ hcId, role }))
}